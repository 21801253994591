<template>
    <span class="badge">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'Badge',
}
</script>

<style lang="scss" scoped>
.badge {
    padding: 4px 6px;
    background-color: $color-gray-light-new;
    border-radius: 6px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.09em;
    transition: all 0.1s;
}
</style>
