<template>
    <div v-if="!sensorData" class="l-padded">
        <VSpinner size="medium" line-fg-color="black" :speed="1" />
    </div>

    <div v-else>
        <TruckTimelineMeasurementFragment
            :tracker="$parent.entry"
            class="u-bb"
        />

        <HiddenForm>
            <template #title>{{ $t('driverData') }}</template>

            <template #form>
                <table>
                    <tr>
                        <th />
                        <th>{{ $t('driver') }} 1</th>
                        <th>{{ $t('driver') }} 2</th>
                    </tr>

                    <tr v-for="(key, iRow) in dataKeys" :key="key">
                        <td>{{ $t(key) }}</td>

                        <td
                            v-for="(item, iColumn) in records[iRow]"
                            :key="iColumn"
                        >
                            <template v-if="item">
                                <div
                                    v-if="key === 'card_presence'"
                                    class="l-inline"
                                >
                                    <ActiveInactiveBadge :active="item.value">
                                        {{
                                            item.value
                                                ? $t('shared.yes')
                                                : $t('shared.no')
                                        }}
                                    </ActiveInactiveBadge>
                                </div>

                                <p v-else>{{ item.value }}</p>

                                <TimeAgo
                                    class="time"
                                    :from-datetime="item.last_update"
                                />
                            </template>

                            <template v-else>
                                –
                            </template>
                        </td>
                    </tr>
                </table>
            </template>
        </HiddenForm>
    </div>
</template>

<script>
import VSpinner from 'vue-simple-spinner'

import { formatHelper } from '@/utils'
import ActiveInactiveBadge from '@/components/redesigned/ActiveInactiveBadge'
import HiddenForm from '@/components/HiddenForm'
import TimeAgo from '@/components/TimeAgo'
import TruckTimelineMeasurementFragment from '@/components/TruckTimelineMeasurementFragment'

export default {
    name: 'AssetTachographView',
    components: {
        ActiveInactiveBadge,
        HiddenForm,
        TimeAgo,
        TruckTimelineMeasurementFragment,
        VSpinner,
    },
    data() {
        return {
            dataKeys: [
                'card_presence',
                'driver_id',
                'driver_name',
                'continuous_driving_time',
                'cumulative_break_time',
                'cumulative_driving_time',
                'current_daily_driving_time',
                'duration_next_break',
                'minimum_daily_rest',
                'minimum_weekly_rest',
                'remaining_time_current_shift',
                'remaining_time_current_week',
                'remaining_time_until_next_break',
                'selected_activity_duration',
                'time_left_until_new_daily_rest_period',
                'time_left_until_next_weekly_rest_period',
                'time_related_state',
                'working_state',
            ],
        }
    },
    computed: {
        records() {
            if (!this.sensorData) {
                return []
            }

            return this.dataKeys.map(key => {
                switch (key) {
                    case 'card_presence':
                        return [
                            this.sensorData[`driver_1_${key}`],
                            this.sensorData[`driver_2_${key}`],
                        ]
                    case 'continuous_driving_time':
                    case 'cumulative_break_time':
                    case 'cumulative_driving_time':
                    case 'current_daily_driving_time':
                    case 'duration_next_break':
                    case 'minimum_daily_rest':
                    case 'minimum_weekly_rest':
                    case 'remaining_time_current_shift':
                    case 'remaining_time_current_week':
                    case 'remaining_time_until_next_break':
                    case 'selected_activity_duration':
                    case 'time_left_until_new_daily_rest_period':
                    case 'time_left_until_next_weekly_rest_period':
                        return this.getDatetimeRecords(key)
                    case 'driver_id':
                    case 'driver_name':
                        return [
                            this.sensorData['driver_1_card_presence']?.value &&
                                this.sensorData[key],
                            this.sensorData['driver_2_card_presence']?.value &&
                                this.sensorData[`${key}_2`],
                        ]
                    case 'time_related_state':
                    case 'working_state':
                        return this.getTranslatedRecords(key)
                    default:
                        return [
                            this.sensorData['driver_1_card_presence']?.value &&
                                this.sensorData[`driver_1_${key}`],
                            this.sensorData['driver_2_card_presence']?.value &&
                                this.sensorData[`driver_2_${key}`],
                        ]
                }
            })
        },
        sensorData() {
            return this.$parent.entryDetailed?.asset_details.sensor_data
        },
    },
    methods: {
        getDatetimeRecords(key) {
            return [`driver_1_${key}`, `driver_2_${key}`].map((dataKey, i) => {
                return (
                    this.sensorData[`driver_${i + 1}_card_presence`]?.value &&
                    this.sensorData[dataKey] && {
                        ...this.sensorData[dataKey],
                        value: formatHelper.hoursAndMinutesDuration(
                            this.sensorData[dataKey].value
                        ),
                    }
                )
            })
        },
        getTranslatedRecords(key) {
            return [`driver_1_${key}`, `driver_2_${key}`].map((dataKey, i) => {
                if (
                    !this.sensorData[dataKey] ||
                    !this.sensorData[`driver_${i + 1}_card_presence`]?.value
                ) {
                    return null
                }

                const translationKey = `shared.tachograph.${key}.${this.sensorData[dataKey].value}`
                return {
                    ...this.sensorData[dataKey],
                    value: this.$root.$te(translationKey)
                        ? this.$t(translationKey)
                        : this.sensorData[dataKey].value,
                }
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "card_presence": "Card presence",
        "continuous_driving_time": "Continuous driving time",
        "cumulative_break_time": "Cumulative break time",
        "cumulative_driving_time": "Cumulative driving time",
        "current_daily_driving_time": "Current daily driving time",
        "driver": "Driver",
        "driverData": "Driver data",
        "driver_id": "Driver ID",
        "driver_name": "Driver name",
        "duration_next_break": "Duration next break",
        "minimum_daily_rest": "Minimum daily rest",
        "minimum_weekly_rest": "Minimum weekly rest",
        "remaining_time_current_shift": "Remaining time of current shift",
        "remaining_time_current_week": "Remaining time of current week",
        "remaining_time_until_next_break": "Remaining time until next break",
        "selected_activity_duration": "Selected activity duration",
        "time_left_until_new_daily_rest_period": "Time left until new daily rest period",
        "time_left_until_next_weekly_rest_period": "Time left until next weekly rest period",
        "time_related_state": "Time related state",
        "working_state": "Working state"
    },
    "de": {
        "card_presence": "Anwesenheit der Karte",
        "continuous_driving_time": "Kontinuierliche Lenkzeit",
        "cumulative_break_time": "Kumulative Pausenzeit",
        "cumulative_driving_time": "Kumulative Lenkzeit",
        "current_daily_driving_time": "Aktuelle tägliche Lenkzeit",
        "driver": "Fahrer",
        "driverData": "Daten zum Fahrer",
        "driver_id": "Fahrer-ID",
        "driver_name": "Name des Fahrers",
        "duration_next_break": "Dauer der nächsten Pause",
        "minimum_daily_rest": "Tägliche Mindestruhezeit",
        "minimum_weekly_rest": "Wöchentliche Mindestruhezeit",
        "remaining_time_current_shift": "Verbleibende Zeit der aktuellen Schicht",
        "remaining_time_current_week": "Verbleibende Zeit der aktuellen Woche",
        "remaining_time_until_next_break": "Verbleibende Zeit bis zur nächsten Pause",
        "selected_activity_duration": "Dauer der ausgewählten Aktivität",
        "time_left_until_new_daily_rest_period": "Verbleibende Zeit bis zur nächsten täglichen Ruhezeit",
        "time_left_until_next_weekly_rest_period": "Verbleibende Zeit bis zur nächsten wöchentlichen Ruhezeit",
        "time_related_state": "Zeitbezogener Zustand",
        "working_state": "Arbeitszustand"
    },
    "fr": {
        "card_presence": "Présence de la carte",
        "continuous_driving_time": "Temps de conduite continu",
        "cumulative_break_time": "Temps de pause cumulé",
        "cumulative_driving_time": "Temps de conduite cumulé",
        "current_daily_driving_time": "Temps de conduite quotidien actuel",
        "driver": "Conducteur",
        "driverData": "Données sur le conducteur",
        "driver_id": "ID du conducteur",
        "driver_name": "Nom du conducteur",
        "duration_next_break": "Durée de la prochaine pause",
        "minimum_daily_rest": "Repos journalier minimum",
        "minimum_weekly_rest": "Repos hebdomadaire minimum",
        "remaining_time_current_shift": "Temps restant de l'équipe actuelle",
        "remaining_time_current_week": "Temps restant de la semaine en cours",
        "remaining_time_until_next_break": "Temps restant jusqu'à la prochaine pause",
        "selected_activity_duration": "Durée de l'activité sélectionnée",
        "time_left_until_new_daily_rest_period": "Temps restant jusqu'à la nouvelle période de repos journalier",
        "time_left_until_next_weekly_rest_period": "Temps restant jusqu'à la prochaine période de repos hebdomadaire",
        "time_related_state": "État lié au temps",
        "working_state": "État de travail"
    },
    "it": {
        "card_presence": "Presenza della carta",
        "continuous_driving_time": "Tempo di guida continuo",
        "cumulative_break_time": "Tempo di interruzione cumulativo",
        "cumulative_driving_time": "Tempo di guida cumulativo",
        "current_daily_driving_time": "Tempo di guida giornaliero attuale",
        "driver": "Conducente",
        "driverData": "Dati del conducente",
        "driver_id": "ID conducente",
        "driver_name": "Nome del conducente",
        "duration_next_break": "Durata della prossima interruzione",
        "minimum_daily_rest": "Riposo minimo giornaliero",
        "minimum_weekly_rest": "Riposo minimo settimanale",
        "remaining_time_current_shift": "Tempo rimanente del turno in corso",
        "remaining_time_current_week": "Tempo rimanente della settimana corrente",
        "remaining_time_until_next_break": "Tempo rimanente fino alla prossima pausa",
        "selected_activity_duration": "Durata dell'attività selezionata",
        "time_left_until_new_daily_rest_period": "Tempo rimanente fino al nuovo periodo di riposo giornaliero",
        "time_left_until_next_weekly_rest_period": "Tempo rimanente fino al prossimo periodo di riposo settimanale",
        "time_related_state": "Stato relativo al tempo",
        "working_state": "Stato di lavoro"
    }
}
</i18n>

<style lang="scss" scoped>
table {
    display: block;
    width: 100%;
    border-top: $style-border;
    border-spacing: 0;
    overflow-x: auto;

    .time {
        font-size: $fs-small;
        color: #808080;
        white-space: nowrap;
    }

    tr {
        &:not(:last-child) {
            td {
                border-bottom: $style-border;
            }
        }

        th,
        td {
            &:first-child {
                white-space: nowrap;

                @include respond-to('for-tablet-down') {
                    white-space: normal;
                }
            }

            &:not(:first-child) {
                border-left: $style-border;
            }

            &:last-child {
                width: 100%;
            }
        }

        th {
            padding: 1rem;
            text-align: left;
            border-bottom: $style-border;
            white-space: nowrap;
        }

        td {
            padding: 0.5rem 1rem;
        }
    }
}
</style>
