<template>
    <Badge class="active-inactive-badge" :class="{ 'badge-active': active }">
        <i class="active-icon" />
        <slot />
    </Badge>
</template>

<script>
import Badge from '@/components/redesigned/Badge'

export default {
    name: 'ActiveInactiveBadge',
    components: {
        Badge,
    },
    props: {
        active: {
            type: [Boolean, Number],
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.active-inactive-badge {
    color: rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}
.active-icon {
    display: inline-block;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transition: all 0.1s;
}
.badge-active {
    background-color: rgba($color-success, 0.1);
    color: $color-success;
    .active-icon {
        background-color: $color-success;
    }
}
</style>
